<template>
  <div class="content-area transfers-table">
    <hero-bar>
      {{ $t('transferList') }}
      <b-tooltip slot="right" :label="$t('refresh')" type="is-dark" position="is-bottom">
        <b-button
          id="refresh-button"
          class="mr-10"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': refreshLoading }"
          icon-left="refresh"
          @click="refresh"
        />
      </b-tooltip>
      <b-tooltip slot="right" :label="$t('exportExcel')" type="is-dark" position="is-bottom">
        <b-button
          id="excelButton"
          class="mr-10 is-hidden-mobile"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': excelLoading }"
          icon-left="file-excel"
          @click="$refs.transferTable.exportExcel(); excelLoading = true;"
        />
      </b-tooltip>
      <b-button
        v-if="checkPermission('create_transfer')"
        id="new-request"
        slot="right"
        tag="router-link"
        :to="{ name: 'transfers.create'}"
        icon-left="plus-circle"
        type="is-link"
      >
        {{ $t('newRequest') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component v-if="stats && statsShow">
        <b-progress :value="100" size="is-medium" show-value>
          {{ $t('total') }}: {{ stats.total }}
        </b-progress>
        <b-progress
          v-for="(stat, index) in stats.status"
          :key="index"
          size="is-medium"
          :value="stat.total"
          :max="stats.total"
          :class="stat.status | showStatus"
          show-value
        >
          {{ $t("status." + stat.status) }} : {{ stat.total }}
        </b-progress>
      </card-component>
      <card-component class="has-table has-mobile-sort-spaced bg-none">
        <transfers-table
          id="transferTable"
          ref="transferTable"
          data-url="/list-transfers-fast"
          @excelChange="actionExcelChange"
          @loadingChange="actionLoadingChange"
        >
          <template slot="header-right">
            <b-tooltip :label="statsShow ? $t('hideStat') : $t('showStat')" type="is-dark" position="is-left">
              <b-button
                class="filter-button is-hidden-mobile"
                size="is-circle"
                icon-left="poll"
                @click="statsShow = !statsShow"
              />
            </b-tooltip>
          </template>
        </transfers-table>
      </card-component>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Transfers',
  title: ({ $i18n }) => $i18n.t('titles.transfers'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    TransfersTable: () => import('@/components/TransfersTable'),
  },
  data () {
    return {
      stats: null,
      excelLoading: false,
      refreshLoading: false,
      excel: 0,
      statsShow: false,
    }
  },
  computed: {},
  created () {
    this.$http.get('stats')
    .then(r => {
      if (r.data) {
        this.stats = r.data;

        if (r.data.unread_notify > 0) {
          this.$store.commit('basic', {
            key: 'hasUpdates',
            value: true
          })
        }
      }
    });
  },
  methods: {
    actionExcelChange (e) {
      setTimeout(() => {
        this.excelLoading = e
      }, 500)
    },
    refresh () {
      document.getElementById('refresh-button').blur();
      this.refreshLoading = true;
      this.excel = 0;
      this.$refs.transferTable.loadTransfers();
    },
    actionLoadingChange (e) {
      this.refreshLoading = e
    }
  },
}
</script>
